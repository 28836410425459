import React, { useEffect, useState } from 'react';
import useBrowserMode from '../../../shared/hooks/useBrowserMode';
import './breadcrumb.scss';

const Breadcrumb = (props: BreadcrumbInterface) => {
    const { content } = props;
    const { isBrowser, window } = useBrowserMode();
    const [paths, setPaths] = useState({});

    const getPaths = () => {
        const paths = window.location.pathname.split('/').slice(1, -2);
        const miga = content.split(' / ').slice(1, -1);

        const result: any = { 0: '/' };
        const minLength = Math.min(paths.length, miga.length);

        let currentPath = '/';
        for (let i = paths.length; i > 0; i--) {
            result[i] = currentPath + paths[paths.length - i] + '/';
            currentPath = result[i];
        }

        const finalResult = {};
        let index = 1;
        for (let i = minLength - 1; i >= 0; i--) {
            finalResult[index++] = result[i + 1];
        }

        finalResult[0] = '/';

        setPaths(finalResult);
    };

    useEffect(() => {
        getPaths();
    }, []);

    const breadcrumbs = (crumbs: string) =>
        crumbs.split('/').map((value, index) => {
            return (
                <li
                    key={index}
                    itemProp="url"
                    className={`crumb url-${index}${
                        props.specialEvent ? ' --special-event' : ''
                    }`}
                >
                    <a
                        title={value}
                        href={paths[index]}
                        rel="nofollow noreferrer"
                    >
                        <span itemProp="title"> {value} </span>
                    </a>
                </li>
            );
        });

    return (
        <nav itemType="https://schema.org/Breadcrumb" className="miga-de-pan">
            <ul
                className={`a-breadcrumb${
                    props.specialEvent ? ' a-breadcrumb--special-event' : ''
                }`}
            >
                {isBrowser && breadcrumbs(content)}
            </ul>
        </nav>
    );
};

interface BreadcrumbInterface {
    content: any;
    specialEvent?: boolean;
}

export default Breadcrumb;
