const transitionElement = () => {
    if (window && 'IntersectionObserver' in window) {
        const observerHidden = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('slow-appear-show');
                } else {
                    entry.target.classList.remove('slow-appear-show');
                }
            });
        });

        const hiddenElems = document.querySelectorAll('.slow-appear');
        if (hiddenElems.length > 0) {
            hiddenElems.forEach((elem) => observerHidden.observe(elem));
        } else {
            console.warn('No elements with class "slow-appear" found.');
        }
    } else {
        console.warn('Intersection Observer is not supported in this browser.');
        // Fallback behavior if needed
        const hiddenElems = document.querySelectorAll('.slow-appear');
        hiddenElems.forEach((elem) => {
            elem.classList.add('slow-appear-show'); // Show all by default if observer isn't supported
        });
    }
};

export default transitionElement;
